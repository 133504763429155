import request from "./http";

export const getDistributorTotal = async (params) => await request({
    method: 'get',
    url: `/distributor/total`,
    params: params
})
export const getDistributorDetails = async (params) => await request({
    method: 'get',
    url: `/distributor/details`,
    params: params
})
export const getDistributorRankInfo = async (params, project_name) => await request({
    method: 'get',
    url: `/distributor/project_income_rank/${project_name}`,
    params: params
})
export const getDistributorAllRankInfo = async (params) => await request({
  method: 'get',
  url: `/distributor/all_income_rank`,
  params: params
})